export const RANKING_SUBTYPE = {
	RANKING_SELECT: 'RANKING_SELECT',
	RANKING_DRAG_AND_DROP: 'RANKING_DRAG_AND_DROP',
	RANKING_IMAGE_SELECT: 'RANKING_IMAGE_SELECT',
	RANKING_IMAGE_DRAG_AND_DROP: 'RANKING_IMAGE_DRAG_AND_DROP',
}

export const RANKING_QUESTION_LOG_ACTIONS = {
	OPTIONS_DISPLAYED: 'OPTIONS_DISPLAYED',
	OPTION_CHANGED: 'OPTION_CHANGED',
	SUBMIT: 'SUBMIT',
	SKIP: 'SKIP',
}

export const RANKING_DRAG_DIRECTION = {
	UP: 'UP',
	DOWN: 'DOWN',
}

export const RANKING_DRAG_AND_DROP_SUBMIT_TIMEOUT = 2000
